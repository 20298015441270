import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "社内勉強会 TechLunch で CLINICS を取りまく監視ツール群について発表しました",
  "date": "2020-04-10T10:29:19.000Z",
  "slug": "entry/2020/04/10/192919",
  "tags": ["medley"],
  "hero": "./2020_04_10.png",
  "heroAlt": "監視ツール"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`皆様こんにちは。 プロダクト開発室 エンジニアの濱中です。`}</p>
    <p>{`新型コロナウイルス感染症が猛威を振るっておりますが、皆様いかがお過ごしでしょうか。先日の緊急事態宣言を受けて、メドレーも全社員が原則リモートで業務に当たっています。オンライン医療事典「MEDLEY」にも、`}<a parentName="p" {...{
        "href": "https://medley.life/covid19s/"
      }}>{`コロナウイルス関連の特集`}</a>{`が組まれていますのでぜひご覧ください。`}</p>
    <p>{`さて先日、社内勉強会 TechLunch にて、弊社の提供するクラウド診療支援システム「CLINICS」のサービス運営で使われている監視ツール群について発表したので、ご紹介させていただきます。`}</p>
    <h1>{`CLINICS の概要と、サービスにおける監視の立ち位置について`}</h1>
    <p><a parentName="p" {...{
        "href": "https://clinics-cloud.com/"
      }}>{`公式ページ`}</a>{`で「クラウド診療支援システム」と銘打っている CLINICS ですが、より詳細には、電子カルテシステム・オンライン予約システムも提供しています。これら予約・診療・カルテの 3 プロダクトで医療現場の業務を一手に引き受けることを目的としたサービスが CLINICS です。`}</p>
    <p>{`このうち、電子カルテシステムは、「`}<a parentName="p" {...{
        "href": "https://www.jma-receipt.jp/"
      }}>{`日医標準レセプトソフト ORCA`}</a>{`」という製品を内包する形で提供しており、ORCA 自体は独立した環境で動作しています。`}</p>
    <p>{`当然ながら、ORCA 自体が CLINICS とは独立した 1 プロダクトであるため、CLINICS 側に問題がなくても ORCA サーバがパフォーマンス低下や不具合を起こしてしまったり、あるいは CLINICS の web サーバとの通信がうまくいかなくなるケースも考えられます。`}</p>
    <p>{`電子カルテシステムは医療機関の診察・会計といった日常業務の重要なパートを担っており、ここで障害が発生すると業務が止まってしまいます（最悪、患者さんをお待たせしてしまうこともあります）。`}</p>
    <p>{`そのため、業務に大きな影響を与える箇所を中心に、複数の監視ツールでアプリケーションの動作状況をチェックしています。`}</p>
    <h1>{`監視ツールあれこれ`}</h1>
    <p>{`ここからは実際に CLINICS で利用しているツールを簡潔にご紹介していきます。`}</p>
    <h2>{`Sentry`}</h2>
    <p><a parentName="p" {...{
        "href": "https://sentry.io/"
      }}>{`https://sentry.io/`}</a></p>
    <p>{`弊社の他プロダクト（「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」、「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」）でも利用している監視ツールです。`}</p>
    <p>{`基本的には、アプリケーションで例外が発生すると自動でアラートを投げてくれますが、アプリケーション側で catch してしまうものや、例外ではないが通知したいイベントについて、任意にアラートをあげることも可能です。`}</p>
    <p>{`CLINICS では例えばアカウント ID やアクセスした API エンドポイント・パラメータなど渡して、エラー調査に活用しています。`}</p>
    <h2>{`Mackerel`}</h2>
    <p><a parentName="p" {...{
        "href": "https://mackerel.io/ja/"
      }}>{`https://mackerel.io/ja/`}</a></p>
    <p>{`GUI で簡単に監視設定が可能なサービスです。`}</p>
    <p>{`前職で使っていた時は GUI でできるところまでしか触っていなかったのですが、設定ファイルを手製すれば、自作スクリプトの出力をチェックしたり、チェックに失敗した時何か処理をしたり…といったマニュアルな監視も可能です。`}</p>
    <p>{`現在 CLINICS においては、ORCA インスタンスがそれぞれ独立して動作していますが、Mackerel ではこの ORCA インスタンスを監視しています。また監視するだけでなく、動作不良を起こした時の再起動処理なども担当しています。`}</p>
    <h2>{`AWS CloudWatch & Lambda`}</h2>
    <p><a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/cloudwatch/"
      }}>{`https://aws.amazon.com/jp/cloudwatch/`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/lambda/"
      }}>{`https://aws.amazon.com/jp/lambda/`}</a></p>
    <p>{`どちらも AWS の提供するツールです。`}</p>
    <p>{`CloudWatch は主にログの記録と監視を行うためのツールです。複数の機能がありますが、CLINICS では主に Logs, Metrics, Alarm を使用しています。`}</p>
    <p>{`Logs はその名の通り、主に AWS のツールが発行するログを収集・検索・閲覧する機能で、問題発生時の HTTP リクエストのパラメータを確認する時などに利用しています。`}</p>
    <p>{`Metrics は、「プロダクト（EC2 ならインスタンス, RDS なら DB インスタンスなど製品の一単位）」×「メトリクス種別（CPU 利用率など）」を一単位として、統計情報を可視化するツールです。`}</p>
    <p>{`それらに閾値を設け、超えた場合にアラートを発行するのが Alarm…という位置付けです。`}</p>
    <p>{`対して Lambda は、そもそも監視ツールではありません。定期実行、あるいは何かのイベント（S3 に何かアップロードされた、など）をトリガーとして、処理を行うツールです。処理した結果を、さらに別のツールへ連携（Slack 通知などの外部連携も含む）させることも可能です。`}</p>
    <p>{`CLINICS では、上記アラート通知のほか、`}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/ja_jp/systems-manager/latest/userguide/session-manager.html"
      }}>{`SessionManager`}</a>{`経由でインスタンスに ssh ログインした時の通知や、時間のかかるインポートタスクなどが終了した時の通知などにも利用しています。`}</p>
    <h2>{`New Relic`}</h2>
    <p><a parentName="p" {...{
        "href": "https://newrelic.co.jp/"
      }}>{`https://newrelic.co.jp/`}</a></p>
    <p>{`New Relic は、CLINICS ではパフォーマンス評価ツールとして利用しています。`}</p>
    <p>{`メインとなるのは APM(Application Performance Monitoring)で、アプリケーション内のどの処理にどれだけ時間がかかったかなどを閲覧することができます。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200410/20200410183154.png",
        "alt": "f:id:medley_inc:20200410183154p:plain",
        "title": "f:id:medley_inc:20200410183154p:plain"
      }}></img></p>
    <p>{`医療機関は時間によって診察ペースが大きく変わるため、1 回 1 回は少しの遅延であっても、業務フローのコアな部分に絡んでいる場合、遅延が響いてくることはあります。`}</p>
    <p>{`このような、「エラーは出ないけど、レスポンスが遅い」というご連絡を受けた時の調査・メンテナンスに主に活躍してくれます。`}</p>
    <p>{`Sentry のようにエラー検知を行なったりもでき、ツール自体の多機能さから取得できる情報の多さが段違いといった印象です。`}</p>
    <h2>{`Firebase Crashlytics`}</h2>
    <p><a parentName="p" {...{
        "href": "https://firebase.google.com/?hl=ja"
      }}>{`https://firebase.google.com/?hl=ja`}</a></p>
    <p>{`Firebase もまた”多機能な”ツールですが、CLINICS では監視機能として Crashlytics を使っています。`}</p>
    <p>{`CLINICS では、患者さんが予約・オンライン診療を行うためのアプリを iOS / Android で公開しており、医療機関向けの Web アプリケーション側と連携して動作します。このネイティブアプリで発生したクラッシュを集計しているのが Crashlytics です。`}</p>
    <p>{`Android の場合は build.gradle に設定を追記してアプリをビルド、iOS の場合は cocoapods で外部ライブラリとしてインストールすることで集計が可能になります。Unity アプリにも対応しているとのことです。`}</p>
    <p>{`その他、監視ではないですがアプリを利用している端末や OS バージョンなどの統計情報の収集も可能です。`}</p>
    <h1>{`PagerDuty による監視集約`}</h1>
    <p><a parentName="p" {...{
        "href": "https://ja.pagerduty.com/"
      }}>{`https://ja.pagerduty.com/`}</a></p>
    <p>{`CLINICS ではここまでに挙げてきたツールを使って監視を行っておりますが、昨年、複数の監視ソースからのアラートを一本化すべく、インシデント管理サービス PagerDuty を導入しました。`}</p>
    <p>{`導入後は、全てのアラートツールはアラートを一旦 PagerDuty に集約し、PagerDuty から Slack 通知を行うという形式になりました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200410/20200410183430.png",
        "alt": "f:id:medley_inc:20200410183430p:plain",
        "title": "f:id:medley_inc:20200410183430p:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200410/20200410183455.png",
        "alt": "f:id:medley_inc:20200410183455p:plain",
        "title": "f:id:medley_inc:20200410183455p:plain"
      }}></img></p>
    <p>{`また、PagerDuty が出すアラートは、担当者が確認してレスポンスを返す（acknowledge）ことを行わずに一定時間が過ぎた場合、電話や SMS での通知へ展開していくように設定することができます。これによって、クリティカルなエラーの見落としを確実に防げるようになりました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`CLINICS で利用している監視ツール+α を紹介させていただきました。`}</p>
    <p>{`これらのツールを駆使しながら、ユーザの皆様に安心してお使いいただけるようサービスの安定稼動に尽力しています。`}</p>
    <p>{`ご覧いただきありがとうございました。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      